import { Component, Vue } from 'vue-property-decorator';

@Component<Dashboard>({})
export default class Dashboard extends Vue {
  public mounted() {
    const user = this.$store.state.Auth;

    if (this.$store.state.isServiceOrganization) {
      if (user.hasRole(['werkvoorbereiding'])) {
        this.$router.push('/werkvoorbereiding');
        return;
      }
      if (user.hasRole(['planning'])) {
        this.$router.push('/planning');
        return;
      }
      if (user.hasRole(['veldwerk'])) {
        this.$router.push('/sba');
        return;
      }
      if (user.hasRole(['bezwaar'])) {
        this.$router.push('/bezwaar');
        return;
      }
    }

    if (! this.$store.state.isServiceOrganization) {
      if (user.hasRole(['pre_controller', 'manager', 'admin', 'helpdesk_tcmg'])) {
        this.$router.push('/bureau');
        return;
      }
    }

    this.$router.push('/reports');
  }
}
